<template>
  <CCard>
    <CCardHeader>
      <CRow class="justify-content-between px-3">
        <div class="d-flex"></div>

        <div class="d-flex">
          <!-- <CSelect
            style="width: 180px"
            class="mx-2 my-0"
            horizontal
            label="Type"
            :value.sync="search.type"
            :options="search.options"
            @change="searchChangeType"
          />
          <CInput
            style="width: 400px"
            class="my-0 px-0"
            :placeholder="`Search ${search.placeholder}`"
            :value.sync="search.value"
          >
            <template #append>
              <CButton color="primary" @click="doApplyFilter">Search</CButton>
              <CButton
                class="ml-2"
                size="sm"
                color="info"
                variant="outline"
                @click="isShowFilter = !isShowFilter"
              >
                <CIcon :name="isShowFilter ? 'cil-x' : 'cil-arrow-bottom'" />
                Filter</CButton
              >
            </template>
          </CInput> -->
        </div>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CCollapse :show="isShowFilter">
        <CCard class="p-3">
          <CRow class="justify-content-between mb-3">
            <h5 class="mx-3">Filter</h5>
            <CButton
              class="ml-auto mx-3"
              variant="outline"
              color="info"
              @click="doResetFilter"
              >Reset</CButton
            >
            <CButton class="mx-3" color="info" @click="doApplyFilter"
              >Apply</CButton
            >
          </CRow>
          <CRow class="justify-content-start">
            <CCol>
              <treeselect
                v-model="filter.status"
                :multiple="true"
                :options="filterData.status"
                placeholder="Select status"
              />
            </CCol>
            <CCol>
              <treeselect
                v-model="filter.business_type"
                :multiple="true"
                :options="filterData.business_type"
                placeholder="BusinessType"
              />
            </CCol>
            <CCol>
              <treeselect
                v-model="filter.categories"
                :multiple="true"
                :options="filterData.categories"
                placeholder="Select category"
              />
            </CCol>
            <CCol>
              <treeselect
                v-model="filter.rating"
                :multiple="true"
                :options="filterData.rating"
                placeholder="Rating"
              />
            </CCol>
            <CCol>
              <date-picker
                class="col-12"
                v-model="filter.dateRangeSelected"
                range
                format="YYYY-MM-DD"
                placeholder="Created date"
              ></date-picker>
            </CCol>
          </CRow>
        </CCard>
      </CCollapse>
      <CDataTable
        hover
        striped
        border
        sorter
        :items="requestProviders"
        :fields="fields"
        :items-per-page="25"
        :active-page="activePage"
        :pagination="{ doubleArrows: false, align: 'center' }"
        @page-change="pageChange"
      >
        <td slot="id" slot-scope="{ item }">
          <CLink :href="'#/sp/' + item.provider.id">
            {{ item.provider.id }}
          </CLink>
        </td>

        <td slot="new_categories" slot-scope="{ item }">
          <template v-for="categoryId in item.metadata.categories">
            <CBadge :color="`primary`" :key="categoryId" class="mr-1">
              {{ getCategorieName(categoryId) }}
            </CBadge>
          </template>
        </td>
        <td slot="categories" slot-scope="{ item }">
          <template v-for="category in item.provider.categories">
            <CBadge :color="`success`" :key="category.id" class="mr-1">
              {{ category.name }}
            </CBadge>
          </template>
        </td>
        <td slot="status" slot-scope="{ item }">
          <CBadge :color="getBadge(item.provider.status)">
            {{ item.provider.status }}
          </CBadge>
        </td>
        <td slot="name" slot-scope="{ item }">
          {{ item.provider.name ? item.provider.name : '-' }}
        </td>
        <td slot="business_name" slot-scope="{ item }">
          {{ item.provider.business_name ? item.provider.business_name : '-' }}
        </td>
        <td slot="type" slot-scope="{ item }">
          {{ item.provider.type ? item.provider.type : '-' }}
        </td>

        <td slot="owner_name" slot-scope="{ item }">
          {{ item.provider.owner.name ? item.provider.owner.name : '-' }}
        </td>
        <td slot="owner_email" slot-scope="{ item }">
          {{ item.provider.owner.email }}
        </td>
        <td slot="owner_phone" slot-scope="{ item }">
          {{
            item.provider.owner.phone_number
              ? item.provider.owner.phone_number
              : '-'
          }}
        </td>

        <td slot="last_login" slot-scope="{ item }">
          {{ moment(item.provider.owner.last_login).fromNow() }}
        </td>
        <td slot="created_at" slot-scope="{ item }">
          {{ item.provider.created_at | moment(datetimeFormat) }}
        </td>
        <td slot="booking_expiry" slot-scope="{ item }">
          {{ item.provider.num_expired_booking }}
        </td>
        <td slot="rate" slot-scope="{ item }">
          {{ item.provider.rate }}
        </td>
        <td slot="cancellation" slot-scope="{ item }">
          {{ item.provider.num_cancelled_booking }}
        </td>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACTION_FETCH_PROVIDER_REQUEST,
  ACTION_FETCH_CATEGORIES,
} from '@/store/actions.type';
import { ProviderBadge } from '@/utils/utils';
import { Common } from '@/constants';
import env from '@/constants/env';
import moment from 'moment';

export default {
  name: 'SPRequests',
  data() {
    return {
      env,
      datetimeFormat: Common.datetimeFormat,
      fields: [
        { key: 'status' },
        {
          key: 'id',
          _style: 'width: 20px',
        },
        { key: 'name', label: 'Alias', _classes: 'font-weight-bold' },
        { key: 'business_name', label: 'BusinessName' },
        { key: 'type', label: 'BusinessType' },
        { key: 'categories', label: 'Categories' },
        { key: 'new_categories', label: 'New Categories' },
        { key: 'owner_name', label: 'Name' },
        { key: 'owner_email', label: 'Email' },
        { key: 'owner_phone', label: 'PhoneNumber' },
        { key: 'last_login', label: 'LastLogin' },
        { key: 'created_at', label: 'CreatedDate' },
        { key: 'rate', label: 'Rating' },
        { key: 'booking_expiry', label: 'BookingExpiry' },
        { key: 'cancellation', label: 'Cancellation' },
      ],
      activePage: 1,
      isShowFilter: false,
      search: {
        type: 'alias',
        value: '',
        placeholder: 'Alias',
        options: [
          { value: 'alias', label: 'Alias' },
          { value: 'id', label: 'Id' },
          { value: 'name', label: 'Name' },
          { value: 'email', label: 'Email' },
          { value: 'phone_number', label: 'Phone' },
        ],
      },
      filter: {
        status: null,
        categories: null,
        dateRangeSelected: null,
        business_type: null,
        rating: null,
      },
      filterData: {
        status: [
          { id: 'active', label: 'Active' },
          { id: 'pending', label: 'Pending' },
          { id: 'approved', label: 'Approved' },
          { id: 'rejected', label: 'Rejected' },
          { id: 'banned', label: 'Banned' },
          { id: 'suspended', label: 'Suspended' },
        ],
        categories: [],
        business_type: [
          { id: 'company', label: 'Company' },
          { id: 'freelancer', label: 'Freelancer' },
        ],
        rating: [
          { id: 0, label: '0' },
          { id: 1, label: '1' },
          { id: 2, label: '2' },
          { id: 3, label: '3' },
          { id: 4, label: '4' },
        ],
      },
    };
  },
  mounted() {
    this.doApplyFilter();

    this.$store.dispatch(ACTION_FETCH_CATEGORIES, {}).then(() => {
      this.filterData.categories = this.categories.map((x) => {
        return { id: x.id, label: x.name };
      });
    });
  },
  computed: {
    ...mapGetters(['requestProviders', 'categories']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      return ProviderBadge(status);
    },

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    searchChangeType() {
      this.search.placeholder = this.search.options.find(
        (x) => x.value == this.search.type
      ).label;
      this.search.value = '';
    },
    getCategorieName(id) {
      let category = this.categories.find((category) => category.id == id);
      return category ? category.name : '';
    },
    doApplyFilter() {
      let query = {};

      if (this.search.value?.length > 0) {
        switch (this.search.type) {
          case 'alias': {
            query = {
              ...query,
              name: this.search.value,
            };
            break;
          }
          case 'id': {
            query = {
              ...query,
              id: this.search.value,
            };
            break;
          }
          case 'name': {
            query = {
              ...query,
              owner_name: this.search.value,
            };
            break;
          }
          case 'email': {
            query = {
              ...query,
              owner_email: this.search.value,
            };
            break;
          }
          case 'phone_number': {
            query = {
              ...query,
              owner_phone: this.search.value,
            };
            break;
          }
        }
      }

      if (this.filter.categories?.length > 0) {
        query = {
          ...query,
          category_id: this.filter.categories,
        };
      }
      if (this.filter.business_type?.length > 0) {
        query = {
          ...query,
          business_type: this.filter.business_type,
        };
      }
      if (this.filter.rating?.length > 0) {
        query = {
          ...query,
          rating: this.filter.rating,
        };
      }

      if (this.filter.status?.length > 0) {
        query = {
          ...query,
          status: this.filter.status,
        };
      }

      if (
        this.filter.dateRangeSelected?.length > 0 &&
        this.filter.dateRangeSelected[0] &&
        this.filter.dateRangeSelected[1]
      ) {
        let fromTo = {
          from: moment(this.filter.dateRangeSelected[0])
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(this.filter.dateRangeSelected[1])
            .endOf('day')
            .format('YYYY-MM-DD'),
        };
        query = {
          ...query,
          ...fromTo,
        };
      }
      this.$store.dispatch(ACTION_FETCH_PROVIDER_REQUEST, query).then(() => {});
    },
    doResetFilter() {
      this.filter.status = null;
      this.filter.categories = null;
      this.filter.dateRangeSelected = null;
      this.filter.business_type = null;
      this.filter.rating = null;

      this.doApplyFilter();
    },
  },
};
</script>
